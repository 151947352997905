@font-face {
  font-family: "Ki-Medium";
  src: local("Ki-Medium"), url(./Ki-Medium.ttf) format("truetype");
}

body {
  margin: 0;
  font-family: "Ki-Medium";
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.preloader {
  width: 40vw;
}

.flex {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.flex-r {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.germs {
  width: 30vw;
  pointer-events: none;
  padding: 2px 5px;
  border: 0.5px rgba(255, 255, 255, 0.721) solid;
}

.skills {
  min-width: 30vw;
  pointer-events: none;
}

.skill-tab {
  width: fit-content;
  padding: 0px 8px;
  border: 0.5px rgba(255, 255, 255, 0.721) solid;
  border-radius: 20px;
}
